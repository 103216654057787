import { Field, useFormikContext } from 'formik';
import React from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, CircularProgress, Grid, Typography } from '@mui/material';

import ConfirmationModal from 'src/components/common/confirmationModal';
import SimpleFileUpload from 'src/components/formik-material-ui/SimpleFileUpload';
import { InternalSection } from 'src/components/sections/section';

import type { TriggerButtonProps } from '../components/common/modal';
import TrashIcon from '../icons/Trash';
import {
    usePatchProjectInfoDescriptionFileAsync,
    useProject,
    useProjectInfo,
} from '../project/state/hook';
import {
    useBriefStatus,
    useObjectivesStatusIsLoading,
    useSuggestionsStatusIsLoading,
} from './state/hooks';
import { ProjectBriefFormData } from './types';
import { getFileNameFromUrl } from './utils/getFileNameFromUrl';

// derived from https://platform.openai.com/docs/assistants/tools/file-search/supported-files#supported-files
const GPT_SUPPORTED_FILE_TYPES = '.doc,.docx,.pdf,.pptx,.md,.txt,.json';

const ProjectBriefDocument = () => {
    const { values, setFieldValue } = useFormikContext<ProjectBriefFormData>();
    const project = useProject();
    const projectInfo = useProjectInfo();

    const isLoading = useBriefStatus() === 'loading';
    const isSuggestionsLoading = useSuggestionsStatusIsLoading();
    const isLoadingObjectives = useObjectivesStatusIsLoading();
    const patchProjectInfoDescriptionFileAsync = usePatchProjectInfoDescriptionFileAsync();
    const handleFileChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.currentTarget.files?.[0];
            patchProjectInfoDescriptionFileAsync({
                id: project?.id,
                projectInfoDescriptionFile: file,
            });
        },
        [project, patchProjectInfoDescriptionFileAsync],
    );

    React.useEffect(() => {
        setFieldValue(
            'isProjectInfoDescriptionFileAnAttachment',
            projectInfo?.isProjectInfoDescriptionFileAnAttachment,
        );
    }, [projectInfo?.isProjectInfoDescriptionFileAnAttachment]);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setFieldValue('isProjectInfoDescriptionFileAnAttachment', checked, false);
    };

    const [fileKey, setFileKey] = React.useState(0);
    const removeFile = () => {
        setFieldValue('projectInfoDescriptionFile', '', true);
        setFileKey((prevKey) => prevKey + 1);
        patchProjectInfoDescriptionFileAsync({
            id: project?.id,
            projectInfoDescriptionFile: '',
        });
    };

    const RemoveFileButton = React.useCallback(
        ({ handleOpen }: TriggerButtonProps) => (
            <LoadingButton
                loading={isSuggestionsLoading || isLoadingObjectives}
                onClick={handleOpen}
                disabled={isSuggestionsLoading || isLoadingObjectives}
                color="error"
                startIcon={<TrashIcon color="error" fontSize="small" />}
            >
                <Trans>Remove File</Trans>
            </LoadingButton>
        ),
        [isSuggestionsLoading, isLoadingObjectives],
    );

    return (
        <InternalSection
            title={
                <Trans>
                    File Uploader <b>(Beta)</b>
                </Trans>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        <Field name="projectInfoDescriptionFile">
                            {({ field, form, meta }: any) => (
                                <SimpleFileUpload
                                    field={field}
                                    key={fileKey}
                                    InputProps={{ onChange: handleFileChange }}
                                    meta={meta}
                                    fileTypes={GPT_SUPPORTED_FILE_TYPES}
                                    form={form}
                                    uploaderSX={{
                                        height: '100px',
                                    }}
                                >
                                    {!isLoading ? (
                                        <>
                                            {field.value && (
                                                <Grid
                                                    sx={{ marginBottom: 1 }}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <Typography variant="body2">
                                                        {field.value?.name ||
                                                            getFileNameFromUrl(field.value)}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <CloudUploadIcon
                                                sx={{ '&:hover': { color: 'primary.main' } }}
                                                fontSize="large"
                                                color="action"
                                            />
                                        </>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </SimpleFileUpload>
                            )}
                        </Field>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" textAlign="left">
                        <Field
                            component={(fieldProps) => (
                                <Checkbox
                                    {...fieldProps}
                                    color="primary"
                                    disabled={
                                        isLoading ||
                                        isLoadingObjectives ||
                                        !values.projectInfoDescriptionFile
                                    }
                                    size="medium"
                                    onChange={handleCheckBoxChange}
                                    checked={values.isProjectInfoDescriptionFileAnAttachment}
                                    data-testid="isProjectInfoDescriptionFileAnAttachmentCheckBox"
                                />
                            )}
                            name="isProjectInfoDescriptionFileAnAttachment"
                        />
                        <Trans>
                            Check this box, if you also want to use this file as an attachment for
                            the vendors
                        </Trans>
                    </Typography>
                </Grid>
                {values?.projectInfoDescriptionFile && (
                    <Grid item sx={{ ml: 1 }}>
                        <ConfirmationModal
                            triggerButtonComponent={RemoveFileButton}
                            onConfirm={removeFile}
                            description={i18n._(
                                'Are you sure you want to delete this file? This is also going to remove the file from the vendor attachment files.',
                            )}
                            confirmButtonText={i18n._('Confirm')}
                            cancelButtonText={i18n._('Cancel')}
                        />
                    </Grid>
                )}
            </Grid>
        </InternalSection>
    );
};
export default ProjectBriefDocument;
