import pick from 'loadsh/pick';
import toast from 'react-hot-toast';

import { i18n } from '@lingui/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProjectAPI } from 'src/apis/projectAPI';
import { ProjectExtraVendorAPI } from 'src/apis/projectExtraVendorAPI';
import { ProjectVendorContactAPI } from 'src/apis/projectVendorContactAPI';
import { ProjectVendorsAPI } from 'src/apis/projectVendorsAPI';
import Fetcher, { FetcherState } from 'src/redux/fetcher';
import { navigateAction } from 'src/sagas/actions';
import { ProjectAnnouncementPayload, ProjectAnnouncementResponse } from 'src/types';
import type { NavigatePayload } from 'src/types/navigation';
import DismissibleToast from 'src/utils/DismissibleToast';

import {
    NewChecklistItem,
    ProjectDetailsPayload,
    ProjectExtraVendorPayload,
    ProjectTimelinePayload,
    ProjectTimelineResponse,
    ProjectVendorContactPayload,
    ProjectVendorsFormData,
} from '../types';
import {
    sanitizeChecklist,
    sanitizeChecklistItem,
    sanitizeProjectAnnouncements,
    sanitizeProjectDetails,
    sanitizeProjectInfo,
    sanitizeProjectTimeline,
    sanitizeProjectVendors,
} from './sanitizers';

const projectAPI = new ProjectAPI();
const projectVendorsAPI = new ProjectVendorsAPI();
const projectVendorContactAPI = new ProjectVendorContactAPI();
const projectExtraVendorAPI = new ProjectExtraVendorAPI();

export const createProjectAsync = createAsyncThunk(
    'project/createProject',
    async (payload: any, thunkAPI) => {
        const projectResponse = await projectAPI.createProject(payload);
        const { dispatch } = thunkAPI;
        dispatch(navigateAction({ path: `/project/${projectResponse?.id}` }));
        return projectResponse;
    },
);

export const creatProjectAndpatchProjectInfoAsync = createAsyncThunk(
    'project/creatProjectAndpatchProjectInfoAsync',
    async (payload: any, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const { projectPayload, projectInfoPayload, projectTimelinePayload } = payload;
        const projectResponse = await projectAPI.createProject(projectPayload);
        const projectInfoResponse = await projectAPI.patchProjectInfo(
            projectInfoPayload,
            projectResponse.id,
        );
        if (projectTimelinePayload) {
            const projectTimelineResponse = await projectAPI.patchProjectTimeline(
                projectTimelinePayload,
                projectResponse.id,
            );
            dispatch({
                type: PatchProjectTimelineFetcher.action.fulfilled.type,
                payload: projectTimelineResponse,
            });
        }

        dispatch({
            type: patchProjectInfoAsync.fulfilled.type,
            payload: projectInfoResponse,
        });
        dispatch(navigateAction({ path: `/project/${projectResponse?.id}` }));

        return projectResponse;
    },
);

export const fetchProjectData = createAsyncThunk(
    'project/fetchProjectData',
    async (id: string, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const project = await projectAPI.getProject(id);
        dispatch({
            type: ProjectInfoFetcher.action.fulfilled.type,
            payload: { data: project.project_info },
        });

        dispatch({
            type: ProjectVendorsFetcher.action.fulfilled.type,
            payload: { data: project.project_vendors },
        });
        dispatch({
            type: ProjectDetailsFetcher.action.fulfilled.type,
            payload: { data: project.project_details },
        });
        dispatch({
            type: ProjectTimelineFetcher.action.fulfilled.type,
            payload: { data: project.project_timeline },
        });
        dispatch({
            type: ProjectChecklistFetcher.action.fulfilled.type,
            payload: { data: project.checklist },
        });

        return project;
    },
);

export const ProjectVendorsFetcher = new Fetcher(
    'projectVendors/fetchProjectVendors',
    async (id: string) => projectVendorsAPI.getProjectVendors(id),
    sanitizeProjectVendors,
);

export const ProjectAnnouncementsFetcher = new Fetcher(
    'projectAnnouncements/fetchProjectAnnouncements',
    async (id: string) => projectAPI.getProjectAnnouncements(id),
    sanitizeProjectAnnouncements,
);

interface CreateAnnouncementThunk {
    dataPayload: ProjectAnnouncementPayload;
    projectID: string;
}

export const ProjectAnnouncementsFetcherCreate = new Fetcher(
    'projectAnnouncements/createProjectAnnouncementAsync',
    async (payload: CreateAnnouncementThunk) => {
        try {
            const response = await projectAPI.createProjectAnnouncement(
                payload.dataPayload,
                payload.projectID,
            );
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    (
        data: ProjectAnnouncementResponse,
        state: FetcherState<Array<ProjectAnnouncementResponse>>,
        _,
    ) => {
        const sanitizedAnnouncements = sanitizeProjectAnnouncements([data]);
        return [...sanitizedAnnouncements, ...state.data];
    },
);

export const ProjectDetailsFetcher = new Fetcher(
    'projectDetails/fetchProjectDetails',
    async () => null,
    sanitizeProjectDetails,
);

export const ProjectInfoFetcher = new Fetcher(
    'projectInfo/fetchProjectInfo',
    async () => null,
    sanitizeProjectInfo,
);

export const ProjectTimelineFetcher = new Fetcher(
    'projectTimeline/fetchProjectTimeline',
    async () => null,
    sanitizeProjectTimeline,
);

const getUpdatedTimeline = (
    data: ProjectTimelineResponse,
    state: FetcherState<ProjectTimelineResponse>,
    action: any,
) => ({
    ...state.data,
    ...sanitizeProjectTimeline(action),
});

export const PatchProjectTimelineFetcher = new Fetcher(
    'projectTimeline/patchProjectTimeline',
    async (payload: PatchProjectTimelinePayload) => {
        try {
            const projectTimelineResponse = await projectAPI.patchProjectTimeline(
                payload.projectTimelinePayload,
                payload.projectId,
            );

            const updatedData = pick(projectTimelineResponse, [
                ...Object.keys(payload.projectTimelinePayload),
                'is_complete',
            ]);
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            return updatedData;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    getUpdatedTimeline,
);

interface PatchProjectInfoPayload {
    projectPayload: any;
    projectInfoPayload: any;
    projectTimelinePayload: ProjectTimelinePayload;
    navigatePayload?: NavigatePayload;
}

export const patchProjectInfoAsync = createAsyncThunk(
    'project/patchProjectInfoAsync',
    async (payload: PatchProjectInfoPayload, thunkAPI) => {
        try {
            const { dispatch } = thunkAPI;
            const projectInfoResponse = await projectAPI.patchProjectInfo(
                payload.projectInfoPayload,
                payload.projectPayload.id,
            );

            const projectResponse = await projectAPI.patchProject(
                payload.projectPayload,
                payload.projectPayload.id,
            );

            const { navigatePayload } = payload;

            const projectTimelineResponse = await projectAPI.patchProjectTimeline(
                payload.projectTimelinePayload,
                payload.projectPayload.id,
            );

            dispatch({
                // any action that will set the project.data
                type: creatProjectAndpatchProjectInfoAsync.fulfilled.type,
                payload: projectResponse,
            });

            dispatch({
                type: PatchProjectTimelineFetcher.action.fulfilled.type,
                payload: projectTimelineResponse,
            });
            dispatch({
                type: ProjectDetailsFetcher.action.fulfilled.type,
                payload: { data: projectResponse.project_details },
            });

            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });

            dispatch(navigateAction(navigatePayload));
            return projectInfoResponse;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

export const patchProjectInfoDescriptionFileAsync = createAsyncThunk(
    'project/patchProjectInfoDescriptionFileAsync',
    async (payload: { projectId: string; projectInfoDescriptionFile: File }, thunkAPI) => {
        try {
            const { dispatch } = thunkAPI;
            const formData = new FormData();
            formData.append('project_info_description_file', payload.projectInfoDescriptionFile);
            const projectInfoResponse = await projectAPI.patchProjectInfo(
                formData,
                payload.projectId,
            );

            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            // the reason this is needed is for updating the attachments in rfp details page.
            dispatch(fetchProjectData(payload.projectId));

            return projectInfoResponse;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

interface PatchProjectDetailsPayload {
    projectId: string;
    projectTimelinePayload: ProjectTimelinePayload;
    projectDetailsPayload: ProjectDetailsPayload;
    navigatePayload?: NavigatePayload;
}

export const patchProjectDetailsAsync = createAsyncThunk(
    'project/patchProjectDetailsAsync',
    async (payload: PatchProjectDetailsPayload, thunkAPI) => {
        try {
            const { dispatch } = thunkAPI;

            const { navigatePayload } = payload;
            const projectTimelineResponse = await projectAPI.patchProjectTimeline(
                payload.projectTimelinePayload,
                payload.projectId,
            );
            const projectDetailsResponse = await projectAPI.patchProjectDetails(
                payload.projectDetailsPayload,
                payload.projectId,
            );

            const updatedData = pick(projectDetailsResponse, [
                ...Object.keys(payload.projectDetailsPayload),
                'is_complete',
                'client_introduction',
            ]);
            dispatch({
                type: PatchProjectTimelineFetcher.action.fulfilled.type,
                payload: projectTimelineResponse,
            });
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            if (navigatePayload) {
                dispatch(navigateAction(navigatePayload));
            }
            return updatedData;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

interface PatchProjectTimelinePayload {
    projectId: string;
    projectTimelinePayload: ProjectTimelinePayload;
    navigatePayload?: NavigatePayload;
}

interface UpdateProjectVendorsPayload {
    companies: number[];
    id: string;
}

export const updateProjectVendorsAsync = createAsyncThunk(
    'project/updateProjectVendorsAsync',
    async (payload: UpdateProjectVendorsPayload) => {
        try {
            const response = await projectVendorsAPI.updateProjectVendors(
                { companies: payload.companies },
                payload.id,
            );
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

interface CreateProjectExtraVendorPayload {
    projectId: string;
    data: ProjectExtraVendorPayload;
}

const getVendorsWithExtraVendorState = (
    data: any,
    state: FetcherState<any>,
): ProjectVendorsFormData => {
    const updatedVendors = state.data;
    updatedVendors.companies.push({
        id: data.id,
        companyName: data.company_name,
        website: data.website,
        logo: null,
        contact: null,
    });
    // we can directly set isComplete here, since we need at least 1 vendor for the step to be complete
    updatedVendors.isComplete = true;

    return updatedVendors;
};

export const ProjectExtraVendorFetcher = new Fetcher(
    'project/createProjectExtraVendor',
    async (payload: CreateProjectExtraVendorPayload) => {
        try {
            const response = await projectExtraVendorAPI.createProjectExtraVendor(
                payload.data,
                payload.projectId,
            );
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    getVendorsWithExtraVendorState,
);

interface CreateProjectVendorContactPayload {
    projectId: string;
    data: ProjectVendorContactPayload;
    vendors: ProjectVendorsFormData;
}

export const createProjectVendorContactAsync = createAsyncThunk(
    'project/createProjectVendorContactAsync',
    async (payload: CreateProjectVendorContactPayload) => {
        try {
            const response = await projectVendorContactAPI.createProjectVendorContact(
                payload.data,
                payload.projectId,
            );
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

interface UpdateProjectVendorContactPayload {
    projectId: string;
    contactId: string;
    data: ProjectVendorContactPayload;
}

export const updateProjectVendorContactAsync = createAsyncThunk(
    'project/updateProjectVendorContactAsync',
    async (payload: UpdateProjectVendorContactPayload, thunkAPI) => {
        try {
            const response = await projectVendorContactAPI.updateProjectVendorContact(
                payload.data,
                payload.projectId,
                payload.contactId,
            );
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            const { dispatch } = thunkAPI;
            dispatch(ProjectVendorsFetcher.action(payload.projectId));
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

export const patchProjectVendorContactAsync = createAsyncThunk(
    'project/patchProjectVendorContactAsync',
    async (payload: UpdateProjectVendorContactPayload) => {
        try {
            const response = await projectVendorContactAPI.patchProjectVendorContact(
                payload.data,
                payload.projectId,
                payload.contactId,
            );
            toast.success(DismissibleToast(i18n.t('Your changes have been saved.')), {
                duration: 4000,
            });
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

export const validateAndAutocompleteProjectVendorContactAsync = createAsyncThunk(
    'project/validateAndAutocompleteProjectVendorContact',
    async (payload: any) => {
        try {
            const response =
                await projectVendorContactAPI.validateAndAutocompleteProjectVendorContact(
                    payload.data,
                    payload.projectId,
                );
            if (response.company && response.user) {
                toast.success(
                    DismissibleToast(i18n.t('Contact Information for the user has been found.')),
                    {
                        duration: 4000,
                    },
                );
            }
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);

interface CreateProjectChecklistItemPayloadThunk {
    dataPayload: NewChecklistItem;
    projectID: number;
}

export const ProjectChecklistFetcher = new Fetcher(
    'projectChecklist/fetchProjectChecklist',
    async () => null,
    sanitizeChecklist,
);

export const ProjectChecklistFetcherCreate = new Fetcher(
    'project/createProjectChecklistItemAsync',
    async (payload: CreateProjectChecklistItemPayloadThunk) => {
        try {
            const response = await projectAPI.createProjectChecklistItem(
                payload.dataPayload,
                payload.projectID,
            );
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    (data, state, _) => {
        const sanitizedChecklistItem = sanitizeChecklistItem(data);
        state.data.checkListItems = [sanitizedChecklistItem, ...state.data.checkListItems];
        return state.data;
    },
);

interface PatchProjectChecklistItemPayload {
    dataPayload: NewChecklistItem;
    itemID: number;
    index: number;
    projectID: number;
}

export const ProjectChecklistFetcherPatch = new Fetcher(
    'project/patchProjectChecklistItemItemAsync',
    async (payload: PatchProjectChecklistItemPayload) => {
        try {
            const response = await projectAPI.patchProjectChecklistItem(
                payload.dataPayload,
                payload.projectID,
                payload.itemID,
            );

            return { updatedItem: response, index: payload.index };
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    (data, state: any, _) => {
        const { updatedItem, index } = data;
        const sanitizedChecklistItem = sanitizeChecklistItem(updatedItem);
        state.data.checkListItems[index] = sanitizedChecklistItem;
        return state.data;
    },
);

interface DeleteProjectChecklistItemPayload {
    projectID: number;
    itemID: number;
    index: number;
}

export const ProjectChecklistFetcherDelete = new Fetcher(
    'project/deleteProjectChecklistItemAsync',
    async (payload: DeleteProjectChecklistItemPayload) => {
        try {
            await projectAPI.deleteProjectChecklistItem(payload?.projectID, payload.itemID);
            return payload.index;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    (dataIndex, state: any, action) => {
        state.data.checkListItems = state.data.checkListItems.filter(
            (item, index) => index !== dataIndex,
        );
        return state.data;
    },
);

export const GenerateChecklistItemsFetcher = new Fetcher(
    'project/generateChecklistItems',
    async (payload: any, thunkAPI): Promise<any> => {
        try {
            const response = await projectAPI.generateProjectCheckListItems(payload?.projectID);

            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        } finally {
            const { dispatch } = thunkAPI;
            dispatch({
                type: 'projectChecklist/setGenerateLoading',
                payload: { loading: false },
            });
        }
    },
    (data, state: any, action) => {
        const sanitizedData = data.map((item) => sanitizeChecklistItem(item));
        const existingDataWithoutCurrentAiItems = state.data.checkListItems.filter(
            (item) => !item.aiGenerated,
        );
        state.data.checkListItems = [...sanitizedData, ...existingDataWithoutCurrentAiItems];
        return state.data;
    },
);

export const BulkDeleteChecklistItemsFetcher = new Fetcher(
    'project/bulkDeleteProjectChecklistItems',
    async (payload: any): Promise<any> => {
        try {
            const { itemIDs, projectID } = payload;
            await projectAPI.bulkDeleteProjectChecklistItems(projectID, itemIDs);
            return itemIDs;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    (itemIDs, state: any, action) => {
        const remainingItems = state.data.checkListItems.filter(
            (item) => !itemIDs.includes(item.id),
        );
        state.data.checkListItems = [...remainingItems];
        return state.data;
    },
);

export const ArchiveProjectFetcher = new Fetcher(
    'project/archiveProject',
    async (payload: any): Promise<any> => {
        try {
            const { projectID } = payload;
            await projectAPI.archive(projectID);
            toast.success(DismissibleToast(i18n.t('The project was successfully archived')));
            return projectID;
        } catch (e) {
            toast.error(DismissibleToast(i18n._('An error occurred while archiving the project.')));
            throw new Error(e);
        }
    },
    (projectID, state: any, action) => {
        state.data.isArchived = true;
        return state.data;
    },
);

export const RestoreProjectFetcher = new Fetcher(
    'project/restoreProject',
    async (payload: any): Promise<any> => {
        try {
            const { projectID } = payload;
            await projectAPI.restore(projectID);
            toast.success(DismissibleToast(i18n.t('The project was successfully restored')));
            return projectID;
        } catch (e) {
            toast.error(DismissibleToast(i18n._('An error occurred while restoring the project.')));
            throw new Error(e);
        }
    },
    (projectID, state: any, action) => {
        state.data.isArchived = false;
        return state.data;
    },
);
