import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

import FileRenderer from 'src/components/files/fileRenderer';
import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import EditAction from 'src/project/components/editAction';
import { useProjectDetails } from 'src/project/state/hook';
import { useDocumentTypes } from 'src/redux/documentTypes/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

import { getFileNameFromUrl } from '../../projectBrief/utils/getFileNameFromUrl';

const Attachments = () => {
    const projectDetails = useProjectDetails();
    const { documents } = projectDetails;
    const documentTypes = useDocumentTypes();
    const documentTypesDict = documentTypes?.reduce((acc, item) => {
        acc[item.id] = getOptionKey(item, 'documentType');
        return acc;
    }, {});

    return (
        <BucketSection
            title={<Trans>Attachments</Trans>}
            actions={<EditAction editRoute="rfp-details#accompanying-documents" />}
        >
            <Bucket xs={12}>
                {documents?.map((item, index) => (
                    <FileRenderer
                        key={index}
                        file={item.file}
                        filePath={getFileNameFromUrl(item.file_path)}
                        type={documentTypesDict[item.type]}
                    />
                ))}
                {documents.length === 0 && <Typography>-</Typography>}
            </Bucket>
        </BucketSection>
    );
};

export default Attachments;
