import { Field, Form, Formik } from 'formik';
import { FC } from 'react';

import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Radio,
    Typography,
} from '@mui/material';

import { FormikFormErrors } from 'src/components/FormikFormErrors';
import { RadioGroup } from 'src/components/formik-material-ui/RadioGroup';
import { useCreateProject } from 'src/project/state/hook';

interface Props {
    open: boolean;
    close: any;
}

export const NewQuoteDialog: FC<Props> = ({ open, close }: Props) => {
    const { i18n } = useLingui();
    const createProject = useCreateProject();
    const onSubmit = () => {
        createProject({ projectTitle: '' });
        close();
    };

    const form = ({ status, submitForm }): JSX.Element => (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}
            maxWidth="sm"
            aria-labelledby="dialog-title"
            open={open}
        >
            <DialogContent>
                <Form>
                    <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                        {i18n._('Please determine the language for your project request')}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Field component={RadioGroup} name="language" data-testid="language">
                            <FormControl>
                                <FormControlLabel
                                    value="en"
                                    control={<Radio />}
                                    label={i18n._('English') as string}
                                    data-testid="englishLanguage"
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    value="de"
                                    control={<Radio />}
                                    label={i18n._('German') as string}
                                    data-testid="germanLanguage"
                                />
                            </FormControl>
                        </Field>
                    </Box>
                    <FormikFormErrors status={status} />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button size="large" onClick={close} data-testid="rfpNewQuoteDialogBackButton">
                    {i18n._('Back')}
                </Button>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={submitForm}
                    data-testid="createQuote"
                >
                    {i18n._('Continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Formik initialValues={{ language: 'en' }} onSubmit={onSubmit}>
            {form}
        </Formik>
    );
};
