import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import SessionManager from 'src/login/sessionManager';
import { useAppDispatch } from 'src/redux/hooks';
import { navigateAction } from 'src/sagas/actions';
import { logout } from 'src/utils/login';

interface GuestGuardProps {
    children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    if (SessionManager.isLoggedIn()) {
        if (window.location.href.includes('login?logoutBefore=true')) {
            logout();
        }
        const url = new URL(window.location.href);
        if (url.searchParams.has('next')) {
            const nextUrl = url.searchParams.get('next');
            dispatch(
                navigateAction({
                    path: nextUrl,
                }),
            );
            return null;
        }
        return <Navigate to="/dashboard/rfp" replace />;
    }

    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default GuestGuard;
