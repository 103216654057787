import type { PostBriefPayload, ProjectBriefFormData } from 'src/projectBrief/types';

import { ApaduaAPI } from './ApaduaAPI';

export class ProjectBriefAPI extends ApaduaAPI {
    resource: string = 'api/project/brief/';

    generateSuggestions(values: ProjectBriefFormData): Promise<any> {
        const {
            internalDescription,
            selectedServiceID,
            industryStandardID,
            language,
            startDate,
            endDate,
        } = values;
        const requestData = {
            internal_description: internalDescription,
            selected_service_id: selectedServiceID,
            industry_standard_id: industryStandardID,
            language,
            start_date: startDate,
            end_date: endDate,
        };

        return this.post('suggestions/', requestData) as any;
    }

    refineObjectives(values: ProjectBriefFormData): Promise<any> {
        const {
            internalDescription,
            projectApproach,
            projectObjectives,
            selectedServiceID,
            industryStandardID,
            language,
            startDate,
            endDate,
        } = values;
        const requestData = {
            internal_description: internalDescription,
            project_approach: projectApproach,
            project_objectives: JSON.stringify(projectObjectives),
            selected_service_id: selectedServiceID,
            industry_standard_id: industryStandardID,
            language,
            start_date: startDate,
            end_date: endDate,
        };

        return this.post('refine-objectives/', requestData) as any;
    }

    projectBriefDocumentSuggestions(payload: any): Promise<any> {
        return this.post('document-suggestions/', payload) as any;
    }

    createBrief(payload: PostBriefPayload) {
        return this.post('', payload);
    }

    getBrief(id: number) {
        return this.get(`/${id}`);
    }

    getAllBriefs() {
        return this.get('') as any;
    }

    patchBrief(id: number, payload: any): PostBriefPayload {
        return this.patch(`${id}/`, payload) as any;
    }

    sendEmail(payload: any) {
        const formData = new FormData();
        formData.append('pdfFile', payload.file, 'project_brief.pdf');
        formData.append('startDate', new Date(payload.startDate).toLocaleDateString());
        formData.append('endDate', new Date(payload.endDate).toLocaleDateString());
        formData.append('projectTitle', payload.projectTitle);
        formData.append('recipients', JSON.stringify(payload.recipients));
        formData.append('personalMessage', payload.personalMessage);
        formData.append('includePersonalEmail', payload.includePersonalEmail);
        return this.post('email/', formData) as any;
    }

    findVendors(payload: any) {
        const jsonData = {
            initial_situation: payload.initialSituation,
            project_approach: payload.projectApproach,
            project_objectives: payload.projectObjectives.map(
                (item) => `${item?.title} ${item?.description}`,
            ),
            project_deliverables: payload.projectDeliverables.map(
                (item) => `${item?.title} ${item?.description}`,
            ),
        };

        const jsonDataStringified = JSON.stringify(jsonData);
        return this.post('keywords-suggestions/', jsonDataStringified) as any;
    }
}
