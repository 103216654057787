import toast from 'react-hot-toast';

import { t } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ElasticSearchAPI } from 'src/apis/ElasticSearchAPI';
import { ProjectAPI } from 'src/apis/projectAPI';
import DismissibleToast from 'src/utils/DismissibleToast';

const api = new ElasticSearchAPI();
const projectAPI = new ProjectAPI();
export const fetchTotalVendorsCount = createAsyncThunk('vendors/totalVendorsCount', async () =>
    api.count(),
);

export const postProjectVendorKeywordSuggestions = createAsyncThunk(
    'vendors/postProjectVendorKeywords',
    async (payload: any) => {
        try {
            const response = await projectAPI.generateProjectVendorKeywords(payload);

            if (response.length === 0) {
                toast.error(
                    DismissibleToast(t`Keywords could not be generated. Please try again.`),
                    { duration: 4000 },
                );
            }
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
);
