import React, { FC, MouseEvent as ME, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { withSearch } from '@elastic/react-search-ui';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Grid,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
    useKeywordSuggestions,
    usePostProjectVendorKeywordSuggestions,
    useStatusIsLoading,
} from 'src/explore/dashboard/state/hooks';

const useToggleStyles = makeStyles((theme: Theme) => ({
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
    },
}));

type SearchToggleButtonsProps = {
    addFilter?: (name: string, value: any, type?: any) => void;
    removeFilter?: (name: string, value?: any, type?: any) => void;
    contentSearch: boolean;
    onToggleChange: (event: ME<HTMLButtonElement, MouseEvent>, value: any) => void;
};

const GenerateKeywordsButton = () => {
    const params = useParams();
    const postProjectVendorKeywordSuggestions = usePostProjectVendorKeywordSuggestions();
    const { id } = params;

    const handleGenerateVendorKeywords = React.useCallback(() => {
        postProjectVendorKeywordSuggestions(Number(id));
    }, [id]);

    const loading = useStatusIsLoading();
    const keywordSuggestions = useKeywordSuggestions();

    return (
        <LoadingButton
            data-testid="generateVendorKeywordsButton"
            loading={loading}
            color="secondary2"
            startIcon={<AutoFixHighIcon />}
            onClick={handleGenerateVendorKeywords}
            size="small"
            variant="contained"
            sx={{ mt: 2 }}
        >
            {keywordSuggestions.length > 0 ? (
                <Trans>Refresh Keywords</Trans>
            ) : (
                <Trans>Generate Keywords</Trans>
            )}
        </LoadingButton>
    );
};

const SearchToggleButtons: FC<SearchToggleButtonsProps> = ({
    addFilter,
    removeFilter,
    contentSearch,
    onToggleChange: handleToggleChange,
}: SearchToggleButtonsProps) => {
    const { i18n } = useLingui();
    const toggleClasses = useToggleStyles();

    const handleToggleChangeWrapper = useCallback((e, newSearchType) => {
        if (newSearchType !== 'contentSearch') {
            addFilter('searchType', 'nameSearch');
        } else {
            removeFilter('searchType');
        }
        handleToggleChange(e, newSearchType);
    }, []);
    const keywordSuggestions = useKeywordSuggestions();

    return (
        <Grid container flexDirection="column" width="auto">
            <Grid item>
                <ToggleButtonGroup
                    size="small"
                    value={contentSearch ? 'contentSearch' : 'nameSearch'}
                    exclusive
                    onChange={handleToggleChangeWrapper}
                    aria-label="Platform"
                    sx={{
                        mr: 2,
                        height: '56px',
                    }}
                >
                    <Tooltip
                        title={i18n._(
                            'Choose this option if you wish to search for companies based on their name.',
                        )}
                    >
                        <ToggleButton
                            classes={toggleClasses}
                            className={contentSearch ? '' : toggleClasses.selected}
                            value="nameSearch"
                        >
                            {i18n._('Company')}
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip
                        title={i18n._(
                            'Choose this option if you want to search for companies based on the content that matches your search words.',
                        )}
                    >
                        <ToggleButton
                            classes={toggleClasses}
                            className={contentSearch ? toggleClasses.selected : ''}
                            value="contentSearch"
                        >
                            {i18n._('Content')}
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </Grid>
            {contentSearch && (
                <Grid item>
                    <GenerateKeywordsButton />
                </Grid>
            )}
            {contentSearch && keywordSuggestions.length ? (
                <Box sx={{ mt: 3, mr: 2, justifyContent: 'flex-end', display: 'flex' }}>
                    <Typography variant="subtitle2" color="primary">
                        <Trans>Other Keywords</Trans>
                    </Typography>
                </Box>
            ) : null}
        </Grid>
    );
};

export default withSearch(({ addFilter, removeFilter }) => ({ addFilter, removeFilter }))(
    SearchToggleButtons,
) as FC<SearchToggleButtonsProps>;
