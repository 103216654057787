import { AxiosError } from 'axios';

import { APIHeaders } from 'src/apis/HttpAPI';
import {
    ProjectDetailsPayload,
    ProjectInfoPayload,
    ProjectTimelinePayload,
} from 'src/project/types';
import { ProjectAnnouncementPayload } from 'src/types';

import { ApaduaAPI } from './ApaduaAPI';

export class ProjectAPI extends ApaduaAPI {
    resource: string = 'api/project/';

    getHeaders(): APIHeaders {
        return {
            ...super.getHeaders(),
            'Cache-Control': 'no-store',
            Pragma: 'no-cache',
            Expires: '0',
        };
    }

    getProject(id: string): any {
        return this.get(`${id}/`);
    }

    getProjectInfo(id: string): any {
        return this.get(`info/${id}/`);
    }

    createProject(payload: any): any {
        return this.post('', payload);
    }

    getProjectAnnouncements(projectId: string): any {
        return this.get(`${projectId}/announcements/`);
    }

    createProjectAnnouncement(payload: ProjectAnnouncementPayload, projectId: string): any {
        return this.post(`${projectId}/announcements/`, payload);
    }

    createProjectChecklistItem(payload: any, projectID: number): any {
        return this.post(`${projectID}/checklist/items/`, payload);
    }

    patchProjectChecklistItem(payload: any, projectID: number, itemID: number): any {
        return this.patch(`${projectID}/checklist/items/${itemID}/`, payload);
    }

    deleteProjectChecklistItem(projectID: number, checklistItemID: number): any {
        return this.delete(`${projectID}/checklist/items/${checklistItemID}/`);
    }

    generateProjectCheckListItems(projectID): any {
        return this.post(`${projectID}/checklist/items/generate_items/`, {});
    }

    bulkDeleteProjectChecklistItems(projectID: number, itemIDs: Array<number>) {
        const payload = {
            item_ids: itemIDs,
        };
        return this.post(`${projectID}/checklist/items/bulk_delete/`, payload);
    }

    patchProjectInfo(payload: ProjectInfoPayload | FormData, id): any {
        return this.patch(`info/${id}/`, payload);
    }

    patchProject(payload: any, id: any): any {
        return this.patch(`${id}/`, payload);
    }

    patchProjectDetails(payload: ProjectDetailsPayload, id: any): any {
        return this.patch(`${id}/project-details/`, payload);
    }

    patchProjectTimeline(payload: ProjectTimelinePayload, id: any): any {
        return this.patch(`${id}/project-timeline/`, payload);
    }

    archive(id: any): any {
        return this.post(`${id}/archive/`, {});
    }

    restore(id: any): any {
        return this.post(`${id}/restore/`, {});
    }

    generateProjectVendorKeywords(projectID: number): any {
        return this.post(`${projectID}/vendors/keyword-suggestions/`, {});
    }

    // eslint-disable-next-line
    handleError(error: AxiosError): any {
        const errors = error.response.data;
        let errorMessage = '';
        if (error.response.status === 500) {
            throw Error('Something went wrong. Please contact your administrator.');
        }
        errors.forEach((err, index) => {
            const { detail, source } = err;
            const { pointer } = source;
            const field = pointer.split('/').pop();
            errorMessage += `[${field}]: ${detail}\n`;
        });
        throw Error(errorMessage);
    }
}
