import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';

import { NewQuoteDialog } from '../../components/rfp/dashboard/tableComponents/NewQuoteDialog';
import { Bucket, BucketContainer } from '../../components/sections/bucketSection';
import SpacingContainer from '../../components/sections/spacingContainer';
import useHasUserReadOnlyAccess from '../../hooks/useHasUserReadOnlyAccess';
import SessionManager from '../../login/sessionManager';
import DismissibleToast from '../../utils/DismissibleToast';

const BriefPage = () => {
    const hasReadOnly = useHasUserReadOnlyAccess();
    const [newQuoteDialogOpen, setNewQuoteDialogOpen] = useState(false);

    const openNewQuoteDialog = () => {
        if (!SessionManager.getCurrentTenantId()) {
            toast.error(DismissibleToast(i18n._('Please select a tenant first.')));
            return;
        }
        setNewQuoteDialogOpen(true);
    };

    const closeNewQuoteDialog = () => {
        setNewQuoteDialogOpen(false);
    };

    return (
        <>
            <BucketContainer isVertical>
                <Bucket sm={12} withDivider={false}>
                    <SpacingContainer justifyContent="center" mt={8}>
                        <Typography variant="h6" color="primary">
                            <Trans>
                                The BRIEF feature was fully implemented in the RFP project creation
                                process. To create a BRIEF, click on &quot;Create new project&quot;
                                in the RFP dashboard!
                            </Trans>
                        </Typography>
                    </SpacingContainer>
                </Bucket>
                <Bucket sm={12}>
                    <SpacingContainer justifyContent="center">
                        <Button
                            sx={{ mr: 1 }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={openNewQuoteDialog}
                            data-testid="createNewProject"
                            disabled={hasReadOnly}
                            startIcon={<AddIcon />}
                        >
                            <Trans>Create new project</Trans>
                        </Button>
                    </SpacingContainer>
                </Bucket>
            </BucketContainer>
            <NewQuoteDialog open={newQuoteDialogOpen} close={closeNewQuoteDialog} />
        </>
    );
};

export default BriefPage;
