export const getFileNameFromUrl = (url: string): string => {
    if (!url) return '';
    const urlWithoutParams = url.split('?')[0];
    const parts = urlWithoutParams.split('____');
    let fileName = parts[0].split('/').pop() || '';
    if (parts.length > 1) {
        const suffix = parts[1].split('.').pop();
        fileName += `.${suffix}`;
    }
    return fileName;
};
