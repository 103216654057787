import { FC } from 'react';

import { Box } from '@mui/material';

import { useKeywordSuggestions } from 'src/explore/dashboard/state/hooks';

import SuggestionChip from './SuggestionChip';

interface GptKeywordSuggestionsProps {
    onSuggestionClick: (label: string) => void;
}

const suggestionChipStyles = {
    opacity: 0.9,
    backgroundColor: 'ochre.light',
    color: 'ochre.contrastText',
    '&:hover': {
        backgroundColor: 'ochre.main',
        color: 'primary.light',
    },
    '& .MuiChip-icon': {
        color: 'ochre.contrastText',
    },
    mr: 0.5,
    mb: 0.5,
};

const GptKeywordSuggestions: FC<GptKeywordSuggestionsProps> = ({
    onSuggestionClick: handleChipClick,
}: GptKeywordSuggestionsProps) => {
    const keywordSuggestions = useKeywordSuggestions();

    return (
        keywordSuggestions.length > 0 && (
            <Box
                style={{
                    padding: '16px 0px 0px',
                }}
            >
                {keywordSuggestions.map((entry) => (
                    <SuggestionChip
                        key={entry}
                        label={entry}
                        onClick={handleChipClick}
                        sx={suggestionChipStyles}
                    />
                ))}
            </Box>
        )
    );
};

export default GptKeywordSuggestions;
