import * as Yup from 'yup';

import { t } from '@lingui/macro';

export const validationSchema = Yup.object().shape({
    initialSituation: Yup.string().required(t`Initial situation`),
    projectApproach: Yup.string().required(t`Project approach`),
    selectedServiceID: Yup.string()
        .nullable()
        .required(t`Service Standard`),
    projectTitle: Yup.string().required(t`Project title`),
});
