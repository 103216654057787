import { Field, useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import InfoIcon from '@mui/icons-material/Info';
import { Grid, IconButton, Typography } from '@mui/material';

import { Modal } from 'src/components/common/modal';
import StyledTextarea from 'src/components/common/styledTextArea';
import CardSection from 'src/components/sections/cardSection';
import { InternalSection } from 'src/components/sections/section';
import ErrorMessage from 'src/components/typography/errorMessage';

import ProjectBriefDocument from './projectBriefDocument';

const triggerButtonComponent = ({ handleOpen }) => (
    <IconButton size="small" color="primary" onClick={handleOpen} aria-label="modal-info">
        <InfoIcon fontSize="small" color="secondary" />
    </IconButton>
);

const ProjectDescriptionModal = () => {
    const { i18n } = useLingui();
    return (
        <Modal
            style={{
                width: 'auto',
                padding: 3,
            }}
            triggerButtonComponent={triggerButtonComponent}
            showTriggerButton
        >
            <Grid>
                <Grid sx={{ mt: 1 }}>
                    <Typography variant="h6">
                        {i18n._(
                            'The project description is the most important input you should provide to the AI. There are different ways to do this. To give you a better feeling for the possible entries, check the examples below:',
                        )}
                    </Typography>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Typography sx={{ mt: 1 }}>
                        <b>{i18n._('1) Entering just a keyword')}</b>
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        {i18n._(
                            "You may enter just a keyword like 'SAP s/4 hana' or 'ESG'. The AI will be able to give you a good, but generic overview of the project.",
                        )}
                    </Typography>

                    <Typography sx={{ mt: 1 }}>
                        <b>{i18n._('2) Entering a colloquial description')}</b>
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        {i18n._(
                            "You may enter a description that sounds like you’ve discussed it with an internal stakeholder. Something like: 'We need to implement SAP s/4 hana in all our production locations around the globe. The main issue is probably the data migration and a roll-out plan to not overwhelm our local site with the new technology. The goal is to finalize this by 2026.'",
                        )}
                    </Typography>

                    <Typography sx={{ mt: 1 }}>
                        <b>{i18n._('3) Entering a formal description')}</b>
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        {i18n._(
                            "You may enter a formal description that you have received by email or defined together with an internal stakeholder for the project. This is an example: 'We are a global manufacturing company with 25 local production sites. The current system we use is SAP R3 with local deployments and a common data pool. The project is supposed to achieve these major milestones: a) analysis of the effort and definition of a functional specification b) roll-out in three pilot sites c) global roll-out and monitoring of the project success.'",
                        )}
                    </Typography>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Typography>
                        {i18n._(
                            'Our suggestion is to play with the options and experience the differences of the output in quality and quantity. A rule of thumb is: The more context you provide, the more specific the output. Just try it and don’t hesitate to play around with the tool.',
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Modal>
    );
};

const ProjectDescriptionForm = () => {
    const { i18n } = useLingui();
    const { values } = useFormikContext() as any;
    const isProjectBriefPDFEnabled = window.ENABLE_PROJECT_BRIEF_PDF;
    return (
        <CardSection
            tooltipText={i18n._(
                'Describe the project in your own words. This information will not be published to external users (e.g. vendors) of apadua. The content will be used to auto-generate your project outline (see below) and help you become more efficient in setting up the project. Your input will also be used to auto-generate the tags for your project (see above) the tags will also help you to search for relevant service providers for your project.',
            )}
            title={i18n._('Your internal description')}
        >
            <Grid direction="row" container>
                <Grid item sm={12} md={isProjectBriefPDFEnabled ? 6 : 12}>
                    <Field name="internalDescription" type="text">
                        {({ meta, form, field }) => (
                            <InternalSection
                                title={i18n._('Describe your project')}
                                header={
                                    <>
                                        <ProjectDescriptionModal />
                                        <ErrorMessage
                                            errorMessage={(meta.touched && meta.error) as string}
                                        />
                                    </>
                                }
                            >
                                <StyledTextarea
                                    error={meta.touched && !!meta.error}
                                    value={field.value}
                                    name="internalDescription"
                                    onChange={(e) =>
                                        form?.setFieldValue(field.name, e.target.value)
                                    }
                                    minRows={5}
                                    disabled={!!values?.projectInfoDescriptionFile}
                                />
                            </InternalSection>
                        )}
                    </Field>
                </Grid>

                {isProjectBriefPDFEnabled && (
                    <>
                        <Grid item sm={12} md={1} alignSelf="center">
                            <Typography variant="h5" display="flex" justifyContent="center">
                                <Trans>or</Trans>
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={5}>
                            <ProjectBriefDocument />
                        </Grid>
                    </>
                )}
            </Grid>
        </CardSection>
    );
};

export default ProjectDescriptionForm;
