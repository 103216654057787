import React from 'react';
import toast from 'react-hot-toast';

import { useLingui } from '@lingui/react';
import ChecklistIcon from '@mui/icons-material/Checklist';
import StarIcon from '@mui/icons-material/Star';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import Drawer from 'src/components/common/drawer';
import useIsUserSupplier from 'src/hooks/useIsUserSupplier';
import {
    useBulkDeleteChecklistItems,
    useCreateProjectCheckListItem,
    useDeleteProjectCheckListItem,
    useFullProject,
    useGenerateChecklistItems,
    usePatchProjectCheckListItem,
    useProjectChecklist,
    useProjectChecklistLoading,
    useProjectInfo,
} from 'src/project/state/hook';
import { ProjectStatus } from 'src/project/types';
import { Z_INDEX } from 'src/theme/zIndex';
import DismissibleToast from 'src/utils/DismissibleToast';

import AddTaskButton from './addTaskButton';
import AiDescription from './aiDescription';
import './animation.css';
import ChecklistHeader from './checkListHeader';
import CheckListItem from './checkListItem';
import InitialStateActions from './initialStateActions';
import { validationSchema } from './validation';

const NewFeatureLabel = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#1976d2',
            color: 'white',
            width: '26px',
            height: '26px',
            borderRadius: '50%',
            ml: 1.5,
        }}
    >
        <StarIcon sx={{ color: 'white', fontSize: '18px' }} />
    </Box>
);

interface ChecklistDrawerProps {
    externalControl?: boolean;
    beforeClose?: () => void;
    actionButton?: React.ReactNode;
}

const ChecklistDrawer = ({ externalControl, beforeClose, actionButton }: ChecklistDrawerProps) => {
    const [open, setOpen] = React.useState(false);
    const [requestRunningID, setRequestRunningID] = React.useState(null);
    const isSupplier = useIsUserSupplier();
    React.useEffect(() => {
        setOpen(externalControl);
    }, [externalControl]);

    const { i18n } = useLingui();
    const theme = useTheme();

    const project = useFullProject();
    const projectInfo = useProjectInfo();
    const checklist = useProjectChecklist() || {};
    const isLoading = useProjectChecklistLoading();

    const createItem = useCreateProjectCheckListItem();
    const updateItem = usePatchProjectCheckListItem();
    const deleteItem = useDeleteProjectCheckListItem();
    const generateChecklistItems = useGenerateChecklistItems();
    const bulkDeleteChecklistItems = useBulkDeleteChecklistItems();

    const initialState = checklist?.checkListItems?.length === 0;

    const handleOpen = () => setOpen(true);
    const handleClose = React.useCallback(() => {
        const itemsToDelete = checklist.checkListItems
            .filter((item) => !item.title && !item.text && requestRunningID !== item.id)
            .map((item) => item.id);

        if (itemsToDelete.length) {
            bulkDeleteChecklistItems(itemsToDelete);
        }

        beforeClose?.();

        setOpen(false);
    }, [bulkDeleteChecklistItems, setOpen, checklist?.checkListItems?.length, requestRunningID]);

    const onBlur = (index, name, value) => {
        const itemToUpdate = checklist?.checkListItems[index];
        // This check helps to avoid unecessary http spam if the value has not changed.
        if (itemToUpdate[name] === value) {
            return;
        }
        const updatedItem = {
            ...itemToUpdate,
            // we wanna update this property only when the user edits the content of the item
            aiGenerated: name === 'title' || name === 'text' ? false : itemToUpdate.aiGenerated,
            [name]: value,
        };

        updateItem(updatedItem, index);
    };
    const addItem = () => {
        createItem();
    };

    const removeItem = (index) => {
        const itemToRemove = checklist?.checkListItems[index];
        deleteItem(itemToRemove?.id, index);
    };

    const handleGenearateSuggestions = React.useCallback(async () => {
        const { initialSituation, selectedServiceID, projectApproach } = projectInfo;

        const { projectTitle } = project;
        const data = {
            initialSituation,
            selectedServiceID,
            projectApproach,
            projectTitle,
        };

        try {
            await validationSchema.validate(data, { abortEarly: false });
            generateChecklistItems();
        } catch (yupErrors) {
            let errorMessage = i18n._(
                'Before using generate suggestions, you must fill in the below fields and SAVE your project:',
            );
            yupErrors.inner.forEach((error) => {
                errorMessage += `\n${error.message}`;
            });
            toast.error(DismissibleToast(errorMessage));
        }
    }, [
        project.projectTitle,
        generateChecklistItems,
        projectInfo.initialSituation,
        projectInfo.internalDescription,
        projectInfo.selectedServiceID,
        projectInfo.projectApproach,
    ]);

    React.useEffect(() => {
        setRequestRunningID(null);
    }, [checklist?.checkListItems?.length]);

    if (
        !window.ENABLE_CHECKLIST ||
        project?.status !== ProjectStatus.draft ||
        isSupplier ||
        !checklist?.id
    ) {
        return null;
    }

    return (
        <>
            <Drawer
                style={{ zIndex: Z_INDEX.ONE_THOUSAND_300 }}
                ModalProps={{
                    onBackdropClick: handleClose,
                }}
                anchor="right"
                open={open}
            >
                <ChecklistHeader
                    initialState={initialState}
                    onClose={handleClose}
                    reloadAI={handleGenearateSuggestions}
                />
                <AiDescription key="unique-key" />
                {actionButton}
                <Grid style={isLoading ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                    {!initialState && <AddTaskButton onClick={addItem} />}
                    {(checklist?.checkListItems || []).map((item, index) => (
                        <CheckListItem
                            key={item.id}
                            index={index}
                            onBlur={onBlur}
                            setRequestRunningID={setRequestRunningID}
                            onRemove={removeItem}
                            id={item.id}
                            {...item}
                        />
                    ))}
                </Grid>
                {initialState && (
                    <InitialStateActions
                        onCreateCustomContent={addItem}
                        generateSuggestion={handleGenearateSuggestions}
                        loading={isLoading}
                    />
                )}
            </Drawer>

            <Button
                sx={{
                    minHeight: '36px',
                    padding: '0px 12px',
                    background: theme.palette.secondary2.main,
                    color: theme.palette.primary.main,
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        background: theme.palette.secondary2.main,
                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.4)',
                        color: theme.palette.primary.main,
                    },
                    animation: 'popJump 0.5s 1 forwards',
                }}
                onClick={handleOpen}
                data-testid="checklist-drawer-button"
            >
                <ChecklistIcon sx={{ mr: 1 }} />
                Checklist
                <NewFeatureLabel />
            </Button>
        </>
    );
};

export default ChecklistDrawer;
