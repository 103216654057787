import type { PostBriefPayload, ProjectBriefFormData } from 'src/projectBrief/types';

export const serializerProjectBriefData = (values: ProjectBriefFormData): PostBriefPayload => {
    const quoteProjectGoals = values.projectObjectives
        .filter((item) => item.title && item.description)
        .map((item) => ({
            description: item.description,
            name: item.title,
        }));
    const projectDeliverables = values.projectDeliverables
        .filter((item) => item.title && item.description)
        .map((item) => ({
            description: item.description,
            name: item.title,
        }));
    const payload = {
        project_name: values.projectTitle,
        internal_description: values.internalDescription,
        main_location: values.primaryProjectLocation,
        project_approach: values.projectApproach || '',
        industry_standard: parseInt(atob(values.industryStandardID).split(':')[1], 10),
        service_standard: parseInt(atob(values.selectedServiceID).split(':')[1], 10),
        quote_project_goals: quoteProjectGoals,
        project_deliverables: projectDeliverables,
        initial_situation: values.initialSituation,
        organization_project_reference: values.internalOrganisationReference,
        start_date: values.startDate,
        end_date: values.endDate,
        remote_consulting: values.engagementMode,
        secondary_locations: values.secondaryProjectLocations,
        budget_estimate: values.budgetEstimate,
        budget_estimate_currency: values.budgetEstimateCurrency,
        budget_approval_status: values.budgetApprovalStatus,
    };
    if (values?.id) {
        // eslint-disable-next-line
        payload['id'] = values.id;
    }
    return payload;
};

export const serializeProjectDocumentSuggestion = (values) => ({
    project_id: values.projectID,
    industry_standard_en: values.selectedIndustry.industryEn,
    project_title: values.projectTitle,
    selected_service_en: values.selectedService.serviceEn,
    start_date: values.startDate,
    end_date: values.endDate,
    internal_description: values.internalDescription,
    language: values.language,
});
