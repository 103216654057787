import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

import {
    briefDataSelector,
    briefFormDataSelector,
    briefLoadingStatusSelector,
    isBriefLoadingSelector,
    isLoadingObjectivesSelector,
    isLoadingSuggestionsSelector,
    suggestionsSelector,
    suggestionsStatusSelector,
} from './selectors';
import { serializeProjectDocumentSuggestion, serializerProjectBriefData } from './serializers';
import {
    creatProjectBrief,
    fetchAllProjectBrief,
    fetchProjectBrief,
    fetchRefinedObjectives,
    fetchSuggestions,
    patchProjectBrief,
    projectBriefDocumentSuggestions,
} from './thunk';

export const useSuggestions = () => useAppSelector(suggestionsSelector);
export const useSuggestionsStatus = () => useAppSelector(suggestionsStatusSelector);

export const useBriefData = () => useAppSelector(briefDataSelector);

export const useBriefFormData = () => useAppSelector(briefFormDataSelector);

export const useBriefStatus = () => useAppSelector(briefLoadingStatusSelector);

export const useBriefStatusIsLoading = () => useAppSelector(isBriefLoadingSelector);

export const useSuggestionsStatusIsLoading = () => useAppSelector(isLoadingSuggestionsSelector);

export const useObjectivesStatusIsLoading = () => useAppSelector(isLoadingObjectivesSelector);

export const useFetchSuggestions = () => {
    const dispatch = useAppDispatch();
    const suggestionsLoadingStatus = useSuggestionsStatus();

    return useCallback(
        (values) => {
            if (suggestionsLoadingStatus !== 'loading') {
                dispatch(fetchSuggestions(values));
            }
        },
        [dispatch, suggestionsLoadingStatus],
    );
};

export const useFetchRefineObjectives = () => {
    const dispatch = useAppDispatch();
    const suggestionsLoadingStatus = useSuggestionsStatus();

    return useCallback(
        (values) => {
            if (suggestionsLoadingStatus !== 'loading') {
                dispatch(fetchRefinedObjectives(values));
            }
        },
        [dispatch, suggestionsLoadingStatus],
    );
};

export const useProjectBriefDocumentSuggestions = () => {
    const dispatch = useAppDispatch();
    const isSuggestionsLoading = useSuggestionsStatusIsLoading();

    return useCallback(
        (values) => {
            if (!isSuggestionsLoading) {
                const payload = serializeProjectDocumentSuggestion(values);
                dispatch(projectBriefDocumentSuggestions(payload));
            }
        },
        [dispatch, isSuggestionsLoading],
    );
};

export const useFetchProjectBrief = () => {
    const dispatch = useAppDispatch();
    const loading = useBriefStatus();

    return useCallback(
        (values) => {
            if (loading !== 'loading') {
                dispatch(fetchProjectBrief(values));
            }
        },
        [dispatch, loading],
    );
};

export const useCreateProjectBrief = () => {
    const dispatch = useAppDispatch();
    const loading = useBriefStatus();

    return useCallback(
        (values) => {
            if (loading !== 'loading') {
                const payload = serializerProjectBriefData(values);
                dispatch(creatProjectBrief(payload));
            }
        },
        [dispatch, loading],
    );
};

export const usePatchProjectBrief = () => {
    const dispatch = useAppDispatch();
    const loading = useBriefStatus();

    return useCallback(
        (values) => {
            if (loading !== 'loading') {
                const payload = serializerProjectBriefData(values);
                dispatch(patchProjectBrief(payload));
            }
        },
        [dispatch, loading],
    );
};

export const useFetchAllProjectBrief = () => {
    const dispatch = useAppDispatch();
    const loading = useBriefStatus();

    return useCallback(() => {
        if (loading !== 'loading') {
            dispatch(fetchAllProjectBrief());
        }
    }, [dispatch, loading]);
};
