import { Form, Formik, FormikValues } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Button, Grid, Typography } from '@mui/material';

import Section from 'src/components/sections/section';
import { useProjectStep } from 'src/project/state/hook';
import useProjectActions from 'src/project/useProjectActions';
import { useProjectBriefGeneration } from 'src/projectBrief/hooks';
import ProjectBriefForm from 'src/projectBrief/projectBriefForm';
import ProjectDescriptionForm from 'src/projectBrief/projectDescriptionForm';
import ProjectOutlineForm from 'src/projectBrief/projectOutlineForm';
import RequesterSection from 'src/projectBrief/requesterSection';
import { ProjectInfoDefaultvalue, resetSuggestions } from 'src/projectBrief/state/reducer';
import DismissibleToast from 'src/utils/DismissibleToast';

import { Modal } from '../components/common/modal';
import ProjectFooter from './projectFooter';
import { useSuggestions } from './state/hooks';
import { ExtendedValidationSchema as validationSchema } from './validation';

interface Props {
    isEditMode?: boolean;
    project: any;
    onSave: (values: FormikValues) => Promise<void>;
    onChange: (values: FormikValues) => void;
    onClear?: () => void;
    title?: React.ReactNode;
    withActions?: boolean;
}

const ProjectBriefContainer = ({
    isEditMode,
    project,
    onSave,
    onChange,
    onClear,
    title,
    withActions = false,
}: Props) => {
    const navigate = useNavigate();
    const { i18n } = useLingui();
    const suggestions = useSuggestions();
    const { nextStep } = useProjectStep();

    const [isSuggestionModalOpen, setIsSuggestionModalOpen] = React.useState(false);

    const dispatch = useDispatch();
    const {
        refineObjectives,
        generateSuggestions,
        handleApplyAll,
        createProjectAction,
        validateProject,
        validateFormProject,
        validateForm,
        formRef,
    } = useProjectBriefGeneration();

    const onNext = () => {
        navigate(nextStep.path);
    };

    const clearAll = React.useCallback(() => {
        if (formRef.current) {
            dispatch(resetSuggestions());
            formRef.current.resetForm();
            formRef.current.setValues(ProjectInfoDefaultvalue);
            onChange(ProjectInfoDefaultvalue);
            onClear?.();
        }
    }, [dispatch, formRef, onClear, onChange]);

    const handleSave = async () => {
        await onSave?.(formRef.current?.values);
        formRef.current.resetForm({ values: formRef.current?.values });
    };

    const handleSelectBrief = (briefItemValues) => {
        formRef.current.resetForm();
        formRef.current.setValues(briefItemValues);
        dispatch(resetSuggestions());
        const newPath = `/dashboard/brief-generation/${briefItemValues.id}`;
        navigate(newPath);
    };

    const handleValidate = async () => {
        const isProjectValid = await validateFormProject(formRef.current.values);
        if (isProjectValid) {
            toast.success(DismissibleToast(i18n._('Your input is valid.')), {
                duration: 4000,
            });
        }
    };

    const actions = useProjectActions({
        onSave: handleSave,
        onValidate: handleValidate,
        withChecklist: isEditMode,
    });

    const handleGenerateSuggestion = React.useCallback(() => {
        if (suggestions?.projectObjectives?.length) {
            setIsSuggestionModalOpen(true);
        } else {
            generateSuggestions(formRef?.current?.values);
        }
    }, [
        setIsSuggestionModalOpen,
        generateSuggestions,
        formRef?.current?.values,
        suggestions?.projectObjectives?.length,
    ]);

    const handleRefineObjectives = React.useCallback(() => {
        refineObjectives(formRef?.current?.values);
    }, [refineObjectives, formRef?.current?.values]);

    const handleModalClose = () => setIsSuggestionModalOpen(false);

    const handleModalConfirm = () => {
        setIsSuggestionModalOpen(false);
        generateSuggestions(formRef?.current?.values);
    };

    return (
        <Section title={title} actions={withActions && actions} actionsDatatestID="projectActions">
            {project?.request?.firstName &&
                project?.request?.lastName &&
                project?.request?.email && <RequesterSection request={project.request} />}
            <Formik
                innerRef={formRef}
                onSubmit={() => null}
                validateOnChange={false}
                validateOnBlur
                validationSchema={validationSchema}
                initialValues={project || ProjectInfoDefaultvalue}
            >
                <Form>
                    <ProjectBriefForm
                        createProjectAction={createProjectAction}
                        validateProject={validateProject}
                        isEditMode={isEditMode}
                        handleSelectBrief={handleSelectBrief}
                        clearAll={clearAll}
                    />
                    <ProjectDescriptionForm />
                    <ProjectOutlineForm
                        handleApplyAll={handleApplyAll}
                        generateSuggestions={handleGenerateSuggestion}
                        refineObjectives={handleRefineObjectives}
                    />
                    {isEditMode && (
                        <ProjectFooter
                            onValidate={handleValidate}
                            onNext={onNext}
                            onSave={handleSave}
                        />
                    )}

                    <Modal
                        showTriggerButton={false}
                        externalControl
                        isOpen={isSuggestionModalOpen}
                        onClose={handleModalClose}
                    >
                        <Grid sx={{ mb: 1 }}>
                            <Typography paragraph align="center">
                                <Trans>
                                    If you generate new suggestions all existing suggestions will be
                                    overwritten. The content on the left hand side will remain
                                    untouched. Do you want to continue?
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignContent="center">
                            <Button
                                size="medium"
                                variant="outlined"
                                color="secondary"
                                onClick={handleModalClose}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button
                                sx={{ ml: 2 }}
                                size="medium"
                                variant="contained"
                                onClick={handleModalConfirm}
                            >
                                <Trans>Confirm</Trans>
                            </Button>
                        </Grid>
                    </Modal>
                </Form>
            </Formik>
        </Section>
    );
};

export default ProjectBriefContainer;
